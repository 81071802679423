import React from 'react';
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import Breadcrumbs from "../components/breadcrumbs";
import Banner from "../services-page/banner";

import Featured from "../home/featured";
import Partner from "../home/partner";
import Technoloylisting from "./technoloylisting";

function Index(props) {
    return (
        <Layout>
            <Seo title='' description=''/>
            <Breadcrumbs link1="TekRevol" link2="Services"/>
            <Banner title="Technologies"/>
            <Technoloylisting/>
            <Featured/>
            <Partner/>
        </Layout>
    );
}

export default Index;